// menu.ts
export interface SubmenuItem {
    name: string;
    routerLink: string;
    active: boolean;
} 

export interface MenuItem {
    id: string;
    icon: string;
    name: string;
    expanded: boolean;
    exact: boolean;
    active: boolean;
    desktop: boolean;
    submenuList: SubmenuItem[];
    parentRouterLink?: string;
}

export function getlinks(): { [key: string]: string } {
    const flattenedMenuList = [...new AdminToggle().menuList, ...new AdminMenuList().menuList, ...new Menu().menuList, ...new MyRamp().menuList];

    const routerLinkMap = flattenedMenuList.reduce((acc, menuItem) => {
        menuItem.submenuList.forEach(submenuItem => {
            acc[submenuItem.name] = submenuItem.routerLink;
        });
        return acc;
    }, {});

    return routerLinkMap;
}




export class AdminToggle {
    menuList: MenuItem[];
    constructor() {
        this.menuList = [{
            id: '15',
            icon: 'supervisor_account',
            name: 'Ramp Admin',
            expanded: false,
            exact: true,
            active: false,
            desktop: true,
            parentRouterLink: 'ramp-admin',//parentRouterLink:proposed-list
            submenuList: [
            ]
        }];
    }
}



export class AdminMenuList {
    menuList: MenuItem[];


    constructor() {
        this.menuList = [
            {
                id: '6',
                icon: 'security',
                name: 'Verify organization',
                expanded: false,
                exact: true,
                active: false,
                desktop: true,
                submenuList: [
                    {
                        name: 'Verification requests',
                        routerLink: '/organization-verification/requests-list/requests',
                        active: false,
                    },
                    {
                        name: 'Verified list',
                        routerLink: '/organization-verification/requests-list/Verified',
                        active: false,
                    },
                ]
            },
            {
                id: '6',
                icon: 'delete',
                name: 'Delete',
                expanded: false,
                exact: true,
                active: false,
                desktop: true,
                submenuList: [

                    {
                        name: 'Delete organization',
                        routerLink: 'popup-organization',
                        active: false,
                    },
                    {
                        name: 'Delete component',
                        routerLink: 'popup-component',
                        active: false,
                    },
                ]
            },
            {
                id: '7',
                icon: 'event',
                name: 'Event submissions',
                expanded: false,
                exact: true,
                active: false,
                desktop: true,
                parentRouterLink: 'event/proposed-list',
                submenuList: [
                ]
            },
            {
                id: '7',
                icon: 'assignment',
                name: 'Service Requests',
                expanded: false,
                exact: true,
                active: false,
                desktop: true,
                submenuList: [
                  {
                    name: 'Service Requests',
                    routerLink: '/service-request/list/admin-inquiries',
                    active: false,
                  },
                  {
                    name: 'Negotiations Dispute',
                    routerLink: '/service-request/list/admin-dispute',
                    active: false,
                  },
                ]
              },

        ]
    }
}

export class Menu {
    menuList: MenuItem[];

    constructor() {
        this.menuList = [
            {
                id: '1',
                icon: 'info',
                name: 'Learn about',
                expanded: false,
                exact: true,
                active: false,
                desktop: true,
                submenuList: [
                    {
                        name: 'Marketplace',
                        routerLink: '/learn-about/marketplace',
                        active: false,
                    },
                    {
                        name: 'Access The RAMP IIoT',
                        routerLink: '/learn-about/access-the-ramp-iiot',
                        active: false,
                    },

                ]
            },
            {
                id: '2',
                icon: 'link',
                name: 'Connect',
                expanded: false,
                exact: true,
                active: false,
                desktop: true,
                submenuList: [

                    {
                        name: 'Find a Partner',
                        routerLink: '/company/catalogue',
                        active: false,
                    },
                    {
                        name: 'Discover Solutions',
                        routerLink: '/component/catalogue',
                        active: false,
                    },
                    {
                        name: 'Get Local Support (DIHs)',
                        routerLink: '/innovation-hub',
                        active: false,
                    },
                    {
                        name: 'Ask for a Service',
                        routerLink: '/service-request/ask-for-service',
                        active: false,
                    }
                ]
            },
            {
                id: '3',
                icon: 'groups',
                name: 'Collaborate',
                expanded: false,
                exact: false,
                active: false,
                desktop: true,
                submenuList: [
                    {
                        name: 'Success Stories',
                        routerLink: 'collaborate/success-stories',
                        active: false,
                    },
                    {
                        name: 'News & Events',
                        routerLink: 'event/event-page-list',
                        active: false,
                    },
                    {
                        name: 'Discussion board',
                        routerLink: '/discussion-board',
                        active: false,
                    },
                    {
                        name: 'Matchmaking Requests',
                        routerLink: '/service-request/list-public',
                        active: false,
                    }
                ]
            },
            {
                id: '4',
                icon: 'sync',
                name: 'Automate',
                expanded: false,
                exact: true,
                active: false,
                desktop: true,
                submenuList: [
                    {
                        name: 'RAMP IIoT Platform',
                        routerLink: '/ramp-iot',
                        active: false,
                    },
                    {
                        name: 'Cloud Data Storage',
                        routerLink: '/automate/cloud-data-storage',
                        active: false,
                    },
                    {
                        name: 'Data Visualisation',
                        routerLink: '/automate/data-visualisation',
                        active: false,
                    },
                    {
                        name: 'Docker Repository',
                        routerLink: '/automate/docker-repository',
                        active: false,
                    },
                    {
                        name: 'AppHub',
                        routerLink: '/web-workspace/app/1',
                        active: false,
                    }
                ]
            },
            
        ]
    }
}
export class MyRamp {
    menuList: MenuItem[];
    constructor() {
        this.menuList = [{
            id: '5',
            icon: 'person',
            name: 'My RAMP',
            expanded: false,
            exact: false,
            active: false,
            desktop: true,
            submenuList: [
                {
                    name: 'My Organisations',
                    routerLink: '/memberships',
                    active: false,
                },
                {
                    name: 'My Matchmaking Dashboard',
                    routerLink: '/service-request/list/inquiries',
                    active: false,
                },         
                {
                    name: 'My Data Visualisations',
                    routerLink: '/dashboard/app',
                    active: false,
                },
                {
                    name: 'My AppHub',
                    routerLink: '/web-workspace/app/2',
                    active: false,
                }
            ]
        }]
    }
}
