import { Component, Input, Output, EventEmitter,TemplateRef, SimpleChanges} from '@angular/core';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent  {

  constructor() { }
  @Input() openModal:boolean;
  @Input() formTemplate: TemplateRef<any> 
  @Output() closeModalEvent = new EventEmitter<boolean>();


  ngOnChanges(changes: SimpleChanges) {   
    if (changes) {           
      this.openModal=changes.openModal.currentValue; 
      ​​
      
      

      // You can execute your function or code here
    }
  }
  


  closeModal(el){
    if(el.target.classList.contains("closeModal")!== true) return    
    this.openModal=false;
    this.closeModalEvent.emit(false);  
  }

}
