import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';
import {  Router } from '@angular/router';

@Injectable()
export class AuthService {
    remainingTime: number;

    userSubject$: BehaviorSubject<User> = new BehaviorSubject(this.emptyUser());

    constructor(
        private keycloackService: KeycloakService,
        private _router: Router,
    ) {
        const keycloak = this.keycloackService.getKeycloakInstance();
      

    }

    async isTokenValidandUserLoggedIn(){
        if(await this.keycloackService.isLoggedIn()==false){
            return false;
        }
        let keycloak = this.keycloackService.getKeycloakInstance();
        if(keycloak.tokenParsed== undefined){           
            return false;
        }
        this.remainingTime = keycloak.tokenParsed.exp - Math.floor(Date.now() / 1000);
        if(this.remainingTime <  0){
            this.logout();
            return false;
        }

        return true;    

    }

    isUserLoggedIn(){

    }


    

    private emptyUser(): User {
        return new User();         
        
    }

    private setUser(keyCloakUser: User,roles:string[]) {     
        
        keyCloakUser.realm_access_roles=roles;      
        this.userSubject$.next(keyCloakUser);
    }

    private setEmptyUser() {
        this.userSubject$.next(this.emptyUser());
    }

    getUser(): Observable<User> {             
        return this.userSubject$.asObservable();
    }

    async login(path?: string) {    
        if (path) {
            this.keycloackService.login().then(() => {
                this._router.navigate([path])
              });
        }
        else {
            this.keycloackService.login()
        }
    }

    async logout() {    
       
        this.keycloackService.logout(          
        ).then(() => {
          
            return true
          });
    }

 
    

    async refreshAuth() {    
        
      

        if (await  this.isTokenValidandUserLoggedIn() != false) {



        let userInfo = await  this.keycloackService.getKeycloakInstance().loadUserInfo();
          
                let   token =this.keycloackService.getKeycloakInstance().token;
                const decodedToken = this.parseJwt(token);  
                let roles=decodedToken.realm_access.roles
              
  
                this.setUser(userInfo as User,roles);

           // }).catch(err => {
              //  console.error(err);
           // });

        } else {           
           
            this.setEmptyUser();
        }
    }

    async getAccessToken() {

        if (await  this.isTokenValidandUserLoggedIn() != false) { 
            let   token =this.keycloackService.getKeycloakInstance().token;          
            return token       
        } else {          
           
            return false;
        }
    }

    parseJwt(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
      }



    async
}
