import { Company } from './company.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { mainApiRoutes } from '../../config/main.apiroutes';
//import { HTTP_CLIENT_HEADERS } from '../../config/main.headers';
import { MainHeadersService } from 'src/app/data/main-datasource/config/main-headers.service';

import { Industry } from '../industry/industry.model';
import { Service } from '../service/service.model';
import { OrgType } from '../orgtype/orgtype.model';
import { Country } from '../country/country.model';
import { EncodeUriUtility } from "src/app/core/utilities/encode-uri.utility";
import {SoftComponent} from 'src/app/data/main-datasource/domain/softcomponent/softcomponent.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService { 
  constructor(private http: HttpClient, private mainHeadersService: MainHeadersService) { }



  getCompanySingle(id: string): Observable<Company> {
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT + id;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<Company>(url, { headers });
  }

  getImageFile(id: string): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT_FILE_DOWNLOAD + id; 
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<any>(url, { headers, responseType: 'blob' as 'json' });
  }


  load(): Observable<Company[]> {
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_LIST_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<Company[]>(url, { headers });
  }

  byidCompanyInfo(id: string): Observable<Company> {
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT + id;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<Company>(url, { headers });
  }


  byid(id: string): Observable<Company> {

    this.mainHeadersService.HTTP_CLIENT_HEADERS();
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT + id;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<Company>(url, { headers });
  }


  create(company: Company, logoFile: File): Observable<Company> {
    const formData = new FormData();
    formData.append('logoFile', logoFile);
    const companyBlob = new Blob([JSON.stringify(company)], { type: 'application/json' });  
    formData.append('companyDto', companyBlob);
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.post<any>(url, formData, { headers, responseType: 'json' })

  }
  //----------------------------------------------------------------------------------//

  SendGroupMessage() {
   

  }
  //----------------------------------------------------------------------------------//

  edit(company: Company, logoFile: File, id: string): Observable<Company> {
    const formData = new FormData();
    formData.append('logoFile', logoFile);
    const companyBlob = new Blob([JSON.stringify(company)], { type: 'application/json' });
    formData.append('companyDto', companyBlob);
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT + id;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.put<Company>(url, formData, { headers, responseType: 'json' });
  }

  delete(id: string): Observable<Company> {
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT + id;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.delete<Company>(url, { headers });
  }

  

  search(
    name: string,
    countryList: Country[],
    industryList: Industry[],
    serviceList: Service[],
    orgTypeList: OrgType[],
    verified: string,
    organizationServiceTypeCtr: string,
    dih: boolean
  ): Observable<Company[]> {


    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SEARCH_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    const params = new HttpParams()
      .set('company-name', name)
      .set('country-name-list', EncodeUriUtility(countryList, "name"))
      .set('manufacturing-industry-list', EncodeUriUtility(industryList, "name"))
      .set('services-industry-list', EncodeUriUtility(industryList, "name"))
      .set('service-name-list', EncodeUriUtility(serviceList, "name"))
      .set('organisation-types-name-list', EncodeUriUtility(orgTypeList, "name"))
      .set('is-verified', verified)
      .set('organization-service-type', organizationServiceTypeCtr)
      .set('dih',dih)
    return this.http.get<Company[]>(url, { params, headers });
  }

  getCompanyComponents(companyID: string): Observable<SoftComponent[]> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_COMPANY_ENDPOINT + companyID;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<SoftComponent[]>(url, {headers});
  }

  
  



}
