

<ng-container *ngIf="buttons.length!=0">
<div class="spacer"></div>
<div class="footer-nav" [style]="'grid-template-columns: repeat('+buttons.length+',1fr)'">
    <div class="button-p" *ngFor="let button of buttons">
        <div class="button-cont" (click)="navOpen(button.link)">
            <div class="mat-icon-box">
                <div *ngIf="button.number!=0" class="number">{{ button.number }}</div>
                <mat-icon class="engraved-effect">{{ button.icon }} </mat-icon>
            </div>
            <span class="engraved-effect">{{ button.name}}</span>
        </div>
    </div>
</div>
</ng-container>