import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByName'
})
export class SortByNamePipe implements PipeTransform {
 // Don't use sort function it works differently in Firefox and Chrome
  transform(array: Array<any> | null): Array<any> | null {
    if (array == null) return null;
    
    const sortedArray = [];
    
    while (array.length > 0) {
      let minIndex = 0;
      for (let i = 1; i < array.length; i++) {
        if (array[i].name < array[minIndex].name) {
          minIndex = i;
        }
      }
      sortedArray.push(array[minIndex]);
      array.splice(minIndex, 1);
    }

  
    
    return sortedArray;
  }

}