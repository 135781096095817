<div class="container nav" [ngClass]='{"hidden":!openCloseNav,"open": isWide ||lockWide,"no-animetion":noAnimation}'>
  <div class="navcont" *ngIf="!isViewMobile" (mouseenter)="isWide=true" (mouseleave)="isWide=false">
    <div class="sidenav" *ngTemplateOutlet="mainMenu">

    </div>
  </div>
  <div class="content" [ngClass]="{'expanded': isWide && openCloseNav, 'full-width': openCloseNav,'no-pad': noPad}">
    <ng-content></ng-content>

  
  </div>
</div>

<ng-template #publicNum>
  <div class="openPublic">{{openPublicMatchmakingRequest}}</div>
</ng-template>


<ng-template #mainMenu>
  <div class="menu-content">
    <div class="menu-content-inner">
      <div class="page-in">
        <div (click)='menusize()' class="sidenav-arrow-top" *ngIf="!isViewTablet">
          <div class="relative">
            <img [ngClass]="{'open':lockWide}" class="first-icon svg" src="./assets/images/icons/left_panel_close.svg"
              alt="">

            <img [ngClass]="{'open':!lockWide}" class="first-icon svg abs"
              src="./assets/images/icons/left_panel_open.svg" alt="">
          </div>

        </div>
        <ng-container *ngIf="isUserRampAdmin">
          <ng-container *ngTemplateOutlet="menulist; context: {$implicit: adminToggle}"></ng-container>
        </ng-container>
        <div class="test" [ngClass]="{'open': !openAdminMenu}">
          <ng-container *ngTemplateOutlet="menulist;context: {$implicit: menuList}"></ng-container>
        </div>
        <ng-container>
          <div class="test" *ngIf="isUserRampAdmin" [ngClass]="{'open': openAdminMenu}">
            <ng-container *ngTemplateOutlet="menulist;context: {$implicit: adminMenuList}"></ng-container>
          </div>
        </ng-container>
      </div>




      <ng-container *ngIf="isViewMobile">
        <div *ngIf="user.name==''" class="menu button" (click)="redirectToSSO()">
          <mat-icon>login</mat-icon>
          <span class="menu_text">Sign in / Register</span>
        </div>
        <div *ngIf="user.name  !== ''" class="menu button" (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span class="menu_text">Log Out</span>
        </div>
      </ng-container>




    </div>
  </div>
</ng-template>

<ng-template #menulist let-menuList>
  <div class="menulist">
    <div *ngFor="let menu of menuList; let i = index" [ngClass]="{'menu-active': menu.active}">
      <div (click)="toggle(menuList,i)" class="menu"
        *ngIf="(menu.icon!='account_circle')|| user.name!=''&& isViewMobile">
        <mat-icon class="first-icon">{{menu.icon}}</mat-icon>
        <div class="textbox">
    
           

          <span class="menu_text" (click)="customAction(menu.parentRouterLink,$event)"
            [routerLink]="menu.submenuList.length==0 ? menu.parentRouterLink : null">{{menu.name}}
            <ng-container *ngIf="menu.name=='Collaborate' &&  openPublicMatchmakingRequest > 0">
              <ng-container *ngTemplateOutlet="publicNum"></ng-container>           
            </ng-container>
          </span>
        </div>
        <ng-container *ngIf="menu.name!='Ramp Admin'">
          <mat-icon class="first-icon arrow_down" [style.display]="menu.submenuList.length > 0 ? 'flex' : 'none'"
            [ngClass]="{'rotate-x': (menu.expanded && (isWide ||lockWide) )}"> keyboard_arrow_down
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="menu.name=='Ramp Admin'">
          <div class="adminIcons" [ngClass]="{'open': AdminIcons=='close','close': AdminIcons=='menu'}">
            <mat-icon class="first-icon menu-icon">menu</mat-icon>
            <mat-icon class="first-icon menu-close">close</mat-icon>
          </div>
        </ng-container>

      </div>
      <div [ngClass]="{'open': (menu.expanded && (isWide ||lockWide) )}" [attr.id]="menu.id" class="submenu">
        <div class="submenu-list">
          <div *ngFor="let submenu of menu.submenuList" class="submenu-item"
            (click)="customAction(submenu.routerLink,$event)" [ngClass]="{'submenu-active':  submenu.active}">
            <span>{{submenu.name}} </span>
            <ng-container *ngIf="submenu.name=='Matchmaking Requests' && openPublicMatchmakingRequest > 0">
              <ng-container *ngTemplateOutlet="publicNum"></ng-container>             
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-modal [openModal]="openModalOrganization" (closeModalEvent)="modalWasClosedEvent($event)"
  [formTemplate]="deleteOrganization"></app-modal>
<app-modal [openModal]="openModalComponent" (closeModalEvent)="modalWasClosedEvent($event)"
  [formTemplate]="deleteComponent"></app-modal>




<ng-template #deleteOrganization>
  <ng-container *ngTemplateOutlet="deleteTemplate;context: {$implicit: deleteOrganizationText}"></ng-container>
</ng-template>
<ng-template #deleteComponent>
  <ng-container *ngTemplateOutlet="deleteTemplate;context: {$implicit: deleteComponentText}"></ng-container>
</ng-template>

<ng-template #deleteTemplate let-deleteText>
  <div class="form-padding flex" [formGroup]="formGroup">
    <h2 style="text-align: center">{{deleteText.text}}</h2>
    <div class="grid78" [ngClass]="{'open':!openDelete}">
      <mat-form-field style="width:100%" appearance="fill" >
        <mat-label>{{deleteText.placeholder}} ID</mat-label>
        <input matInput placeholder="" type="text" #inputid formControlName="idInput" /> 
        <mat-hint>Enter the {{deleteText.placeholder}} ID of the {{deleteText.placeholder}} you want to
          delete</mat-hint>
          <mat-error *ngIf="idInput.hasError('required')">{{deleteText.placeholder}} ID is Required</mat-error>
          <mat-error *ngIf="idInput.hasError('invalidUUIDFormat') && !idInput.hasError('required')">{{deleteText.placeholder}} ID is not in UUID format</mat-error>



      </mat-form-field>


      <div class="gridDelete68">
        <div class="text68">Are you sure you want to delete {{deleteText.placeholder}}<br>with ID {{inputid.value}}</div>
        <button mat-raised-button color="warn" (click)="deletefunction(deleteText,inputid.value)">Delete</button>
        <button mat-raised-button color="primary" (click)="cancelfunction()">Cancel</button>
      </div>
    </div>

  </div>
  <div class="form-footer">
    <button mat-raised-button style="margin-right: 10px;" [disabled]="openDelete"
      (click)="predeletefunction(inputid.value)" class="right" color="warn">Delete</button>
    <button mat-raised-button [disabled]="openDelete" (click)="editfunction(deleteText.link,inputid.value)" class="right"   color="primary">Edit</button>
  </div>
</ng-template>


<slide-page *ngIf="isViewMobile" [navTemplate]="mainMenu" [slideName]="'mainMenu'"></slide-page>