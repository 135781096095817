import { HTTP_KEYCLAOK_CLIENT_HEADERS } from './config/keycloak.headers';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { keycloakApiRoutes } from './config/keycloak.apiroutes';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) { }

  joinToGroup(email: string, groupId: string) {
    const url = environment.apiRootUrl + keycloakApiRoutes.KEYCLOAK_USER_PATH + email + keycloakApiRoutes.GROYP_PATH + groupId;
    console.log("joinToGroup",url);
    return this.http.post(url, { HTTP_KEYCLAOK_CLIENT_HEADERS });
  }

  joinToSubgroup(email: string, groupId: string, subgroup: string) {
    const url = environment.apiRootUrl + keycloakApiRoutes.KEYCLOAK_USER_PATH + email + keycloakApiRoutes.GROYP_PATH + groupId + keycloakApiRoutes.SUBGROYP_PATH + subgroup;
    console.log("joinToSubgroup",url);
    return this.http.post(url, { HTTP_KEYCLAOK_CLIENT_HEADERS });
  }
}
