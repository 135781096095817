import {Injectable} from '@angular/core';
import {of, Observable} from 'rxjs';
import {SoftComponent} from './softcomponent.model';
import {environment} from "../../../../../environments/environment";
import {mainApiRoutes} from "../../config/main.apiroutes";
import { MainHeadersService} from 'src/app/data/main-datasource/config/main-headers.service';
import {HttpClient, HttpParams} from "@angular/common/http";
import {EncodeUriUtility} from "src/app/core/utilities/encode-uri.utility";


@Injectable({
  providedIn: 'root'
})
export class SoftcomponentService {
  constructor(private http: HttpClient,private mainHeadersService:MainHeadersService) {
  }

  
  byid(id: string): Observable<SoftComponent> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SINGLE_ENDPOINT + id;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<SoftComponent>(url, {headers});
  }

  loadKeyWords(): Observable<string[]> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SEARCH_KEYWORDS;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<string[]>(url, {headers});
  }


  loadIndustries(): Observable<string[]> {
    const url = environment.apiRootUrl + mainApiRoutes.INDUSTRY_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<string[]>(url, {headers});
  }

  loadSupportWorkers(): Observable<string[]> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SUPPORT_WORKERS_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<string[]>(url, {headers});
  }

  loadSupportTasks(): Observable<string[]> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SUPPORT_TASKS_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<string[]>(url, {headers});
  }

  load(): Observable<SoftComponent[]> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_LIST_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<SoftComponent[]>(url, {headers});
  }

  byCompanyId(companyID: string): Observable<SoftComponent[]> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_COMPANY_ENDPOINT + companyID;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<SoftComponent[]>(url, {headers});
  }

  create(component: SoftComponent): Observable<SoftComponent> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SINGLE_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.post<SoftComponent>(url, component, {headers});
  }

  update(component: SoftComponent): Observable<SoftComponent> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SINGLE_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.put<SoftComponent>(url, component, {headers});
  }


  getMessages(AccessToken,messageBody): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.MESSAGING_APP_ENDPOINT;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS_WITH_BEARER(AccessToken);
    return this.http.post<any>(url,messageBody, {headers});
  }


  encodeURIstring(value: string): string {     
    let newvalue:string="";
    [...value].forEach((el,index)=>{
      newvalue+=encodeURIComponent(el);
      if (index != [...value].length - 1){
        newvalue+=",";        
    }      
    });    
    
    return newvalue; 
  }

  search(
    name: string,
    industryList: string,
    rampIotCompatible: boolean,
    RAMPpartner:boolean,
    licenses:string,
    keywords:string,
    download:boolean,
    lastupdate:number, 
    supportWorkers:string, 
    supportTasks:string,   

  ): Observable<SoftComponent[]> {
    

  
 

    
    
  
   


    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SEARCH_ENDPOINT;    
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    let params = new HttpParams()
      .set('term', name)
      .set('rampIotCompatible', rampIotCompatible)
      .set('rampPartner', RAMPpartner)     
      .set('applicableIndustries', EncodeUriUtility(industryList,""))
      .set('download', download)
      .set('keywords', keywords)
      .set('lastUpdate', lastupdate)
      .set('license', EncodeUriUtility(licenses,""))
      .set('supportWorkers',EncodeUriUtility(supportWorkers,""))
      .set('supportTasks', EncodeUriUtility(supportTasks,""));
    

   
     
           
      
    return this.http.get<SoftComponent[]>(url, {params, headers});
  }

  delete(componentId: string): Observable<SoftComponent> {
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SINGLE_ENDPOINT + componentId;  
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.delete<SoftComponent>(url, {headers});
  }
}
