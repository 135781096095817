export const mainApiRoutes = {
  COUNTRY_ENDPOINT: '/ms/company/api/v1/countries',
  INDUSTRY_ENDPOINT: '/ms/company/api/v1/industries',
  ORGTYPE_ENDPOINT: '/ms/company/api/v1/organisations',
  SERVICE_ENDPOINT: '/ms/company/api/v1/services',

  COMPANY_LIST_ENDPOINT: '/ms/company/api/v1/companies',
  COMPANY_SINGLE_ENDPOINT: '/ms/company/api/v1/company/',
  COMPANY_SINGLE_ENDPOINT_FILE_DOWNLOAD: '/ms/company/api/v1/file/download/',
  COMPANY_SEARCH_ENDPOINT: '/ms/company/api/v1/companies/search',

  COMPANY_MESSAGING_ALL_MESSAGES: "/ms/company/api/v1/message/get-all?groupId=/",
  COMPANY_MESSAGING_SEND_MESSAGE: "/ms/company/api/v1/message/send/",
  COMPANY_GET_ALL_CHAT_GROUPS: "/ms/company/api/v1/general-chat/get-chat-list/",
  COMPANY_CREATE_CHAT: (companyId: string, receiverId: string) => { return `/ms/company/api/v1/general-chat/create?companyId=${companyId}&receiverId=${receiverId}` },
  COMPANY_CHECK_IF_CHAT_EXISTS: (companyId: string, receiverId: string) => { return `/ms/company/api/v1/general-chat/check-for-existing-chat?companyId=${companyId}&receiverId=${receiverId}` },
  COMPANY_TO_COMPANY_GET_ALL_MESSAGES: "/ms/company/api/v1/message/get-messages/",
  COMPANY_TO_COMPANY_SEND_MESSAGE: "/ms/company/api/v1/message/send/",
  COMPANY_DELETE_USER_FROM_GROUP: (group: string, userEmail: string, targetUserEmail: string) => { return `/ms/company/api/v1/group/${group}?userEmail=${userEmail}&targetUserEmail=${targetUserEmail}` },
  COMPANY_SEND_INVITATION_TO_USER: (email: string, id: string) => {  return `/ms/company/api/v1/invitation/user/${email}/group/${id}`},


  EVENT_CREATE: "/ms/company/api/v1/event/create",
  EVENT_GET_SUBMISSIONS: "/ms/company/api/v1/event/get-event-submissions",
  EVENT_UPDATE: "/ms/company/api/v1/event/update/",
  EVENT_FILTERING: "/ms/company/api/v1/event/filter",
  EVENT_GET_PUBLISHED_EVENTS: "/ms/company/api/v1/event/get-published-events",
  EVENT_GET_PAST_EVENTS:"/ms/company/api/v1/event/get-past-events/",



  INVITATION_COMPANY_ENDPOINT: '/ms/invitation/api/v1/companyid/',
  INVITATION_USER_ENDPOINT: '/ms/invitation/api/v1/email/',
  INVITATION_ENDPOINT: '/ms/invitation/api/v1/invitationid/',

  KEYCLOAK_SERVICE_USER_PERMISSION: '/ms/company/api/v1/userPermissionInfo/group/',
  KEYCLOAK_SERVICE_UPDATE_PERMISSION: '/ms/company/api/v1/userPermissionInfo/updatePermission',

  COMPONENT_LIST_ENDPOINT: '/ms/component/api/v1/component',
  COMPONENT_SEARCH_ENDPOINT: '/ms/component/api/v1/component/search-by-term',
  COMPONENT_SEARCH_KEYWORDS: '/ms/component/api/v1/component/keywords',
  COMPONENT_SINGLE_ENDPOINT: '/ms/component/api/v1/component/',
  COMPONENT_COMPANY_ENDPOINT: '/ms/component/api/v1/company/',
  COMPONENT_SUPPORT_WORKERS_ENDPOINT: '/ms/component/api/v1/support-workers',
  COMPONENT_SUPPORT_TASKS_ENDPOINT: '/ms/component/api/v1/support-tasks',

  SERVICE_REQUEST: '/ms/company/api/v1/service-request',
  SERVICE_REQUEST_PROVIDER_COMPONENTS: '/ms/company/api/v1/service-request/get-components/',
  SERVICE_REQUEST_UPLOAD_PDF: '/ms/company/api/v1/service-request/upload-contract/',
  SERVICE_REQUEST_GET_FILE: '/ms/company/api/v1/service-request/view-contract/',
  SERVICE_REQUEST_GET_PREVIOUS_CONTRACTS: '/ms/company/api/v1/service-request/previous-versions/',
  SERVICE_REQUEST_POST_NEW_MESSAGE: '/ms/company/api/v1/service-request-chat/send/service/',
  SERVICE_REQUEST_POST_UPLOAD_FILE: '/ms/company/api/v1/service-request-chat/upload-file/service/',
  SERVICE_REQUEST_GET_FILE_CHAT: '/ms/company/api/v1/service-request-chat/download-file/',
  SERVICE_REQUEST_GET_ALL_CHAT: '/ms/company/api/v1/service-request-chat/all-messages/',
  SERVICE_REQUEST_GET_ALL_NEEDS: '/ms/company/api/v1/service-request/get-needs?sid=',
  SERVICE_REQUEST_GET_CONSUMER_MAIL: '/ms/company/api/v1/service-request/get-email-by-sid?sid=',
  SERVICE_REQUEST_PRE_SELECTED_COMPONENTS: '/ms/company/api/v1/service-request/get-tools?sid=',
  SERVICE_REQUEST_GET_ALL: '/ms/company/api/v1/service-request/list',

  
  
  INQUIRY_SERVICE_REQUEST_CREATE: "/ms/company/api/v1/pre-service-request/request-service-form",
  INQUIRY_SERVICE_REQUEST_GET: "/ms/company/api/v1/pre-service-request/",
  INQUIRY_SERVICE_REQUEST_GET_BU: "/ms/company/api/v1/pre-service-request/",
  INQUIRY_SERVICE_REQUEST_UPDATE: "/ms/company/api/v1/pre-service-request/update/",
  INQUIRY_SERVICE_REQUEST_CREATE_SERVICE_REQUEST: "/ms/company/api/v1/service-request/request-form-create",
  INQUIRY_SERVICE_REQUEST_CREATE_OFFER: "/ms/company/api/v1/offer/create/",
  INQUIRY_SERVICE_REQUEST_UPDATE_OFFER: "/ms/company/api/v1/offer/update",
  INQUIRY_SERVICE_REQUEST_GET_OFFERS: "/ms/company/api/v1/offer/get-by-service-inquiry/",
  INQUIRY_SERVICE_REQUEST_UPDATE_OFFERS:"/ms/company/api/v1/offer/update",
  //----------------------------------
  SERVICE_INQUIRY_GET_ALL_CHAT: "/ms/company/api/v1/pre-service-request/all-messages/",
  SERVICE_INQUIRY_POST_NEW_MESSAGE: "/ms/company/api/v1/service-request-chat/send/pre-service/",
  SERVICE_INQUIRY_POST_UPLOAD_FILE: "/ms/company/api/v1/service-request-chat/upload-file/pre-service/",
  SERVICE_INQUIRY_GET_ALL: '/ms/company/api/v1/pre-service-request/list',
  SERVICE_INQUIRY_GET_ALL_PUBLIC: '/ms/company/api/v1/pre-service-request/get-public-list',
  //-------------------------------------

  MESSAGING_APP_ENDPOINT: '/ms/company/api/v1/message/send',

  VERIFICATION_REQUEST: '/ms/company/api/v1/verification-request/',
  VERIFICATION_REQUEST_CREATE: '/ms/company/api/v1/verification-request/create/',
  VERIFICATION_REQUEST_GET_ALL: '/ms/company/api/v1/verification-request/get-all',
  VERIFICATION_REQUEST_DELETE_FILE: (VerificationRequestID: string, fileIDstring: string) => { return `/ms/company/api/v1/verification-request/${VerificationRequestID}/delete-file/${fileIDstring}` },
  VERIFICATION_REQUEST_DOWNLOAD_FILE: "/ms/company/api/v1/verification-request/download-file/",
  VERIFICATION_REQUEST_SEND_MESSAGE: '/ms/company/api/v1/service-request-chat/send/verification/',
  VERIFICATION_REQUEST_UPLOAD_FILE: '/ms/company/api/v1/service-request-chat/upload-file/verification/',
  VERIFICATION_REQUEST_ALL_MESSAGES: '/ms/company/api/v1/verification-request/all-messages/',

  DIGITAL_INNOVATION_HUB_GET_COMPANIES: '/ms/company/api/v1/companies/get-by-DIH/',

  PROFILE_EDIT: (firstName: string, lastName: string, email: string) => { return `/ms/company/api/v1/edit-user/${email}?firstName=${firstName}&lastName=${lastName}` },
  PROFILE_DELETE: "/ms/company/api/v1/delete-account/",

  CONFLICT_RESOLUTION_CREATE:"/ms/company/api/v1/conflictResolution",
  CONFLICT_RESOLUTION_GET:"/ms/company/api/v1/conflictResolution/",
  CONFLICT_RESOLUTION_UPDATE:"/ms/company/api/v1/conflictResolution/",
  CONFLICT_RESOLUTION_DOWNLOAD_FILE:"/ms/company/api/v1/conflictResolution/download-file/",
  CONFLICT_RESOLUTION_POST_NEW_MESSAGE:"/ms/company/api/v1/service-request-chat/send/conflict-resolution/",
  CONFLICT_RESOLUTION_POST_UPLOAD_FILE:"/ms/company/api/v1/service-request-chat/upload-file/conflict-resolution/",
  CONFLICT_RESOLUTION_GET_ALL_CHAT:"/ms/company/api/v1/service-request-chat/all-messages/conflict-resolution/",
  CONFLICT_RESOLUTION_GET_ALL_ADMIN:"/ms/company/api/v1/service-request/get-list-with-conflict-resolution/",

  GET_MY_OFFERS: "/ms/company/api/v1/offer/get-my-offers",


};

export type mainApiRoutesType = keyof typeof mainApiRoutes;
