<div class="head_parent">
  <mat-toolbar *ngIf="!isViewMobile" fxLayout="row" class="toolbar" color="primary">
    <a routerLink='/home' class="ramp-logo"><img class="img-logo" src={{logoImageUrl}} alt="RAMP"></a>
    <span fxFlex></span>
    <div *ngIf="user.email==='' else loggedIn">
      <button mat-raised-button class="signin-btn" (click)="redirectToSSO()">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
          <mat-icon>login</mat-icon>
          <mat-label>Sign in / Register</mat-label>
        </div>
      </button>
    </div>
  </mat-toolbar>


  <div *ngIf="isViewMobile" class="mobile_header">

    <div class="menu-left m-box">
      <button class="sandwich-button" [ngClass]="{'open':isNavOpen}" (click)="onNavCheinge('mainMenu')">

        <div class="nav-icon3">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
    </div>


    <div class="menu-center m-box">
      <a routerLink='/home' class="ramp-logo"><img class="img-logo" src={{logoImageUrl}} alt="RAMP"></a>
    </div>


    <div class="menu-right m-box">
      <div *ngIf=" user.email==='' else loggedInMobile">
        <div class="icon-r" (click)="redirectToSSO()">
          <mat-icon>login</mat-icon>
        </div>
      </div>
    </div>



  </div>
  <div class="sandwich down">
    <button class="sandwich-button " [ngClass]="{'open':isNavOpen }" (click)="onNavCheinge('mainMenu')">
      <div class="nav-icon3">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

    </button>
  </div>


</div>







<ng-template #loggedIn>
  <div class="profile-button"  [matMenuTriggerFor]="userMenu">
    <span>{{user.name}}</span>  <mat-icon>account_circle</mat-icon>
   
  </div>
</ng-template>

<ng-template #loggedInMobile>
  <div class="icon-r" [matMenuTriggerFor]="userMenu">
    <mat-icon>account_circle</mat-icon>
  </div>
</ng-template>



<mat-menu #userMenu="matMenu">
  <a>
    <button mat-menu-item routerLink="/profile">
      <mat-icon>person</mat-icon>Profile
    </button>
  </a>
  <a routerLink="/memberships">
    <button mat-menu-item>
      <mat-icon>account_balance</mat-icon>
      <mat-label>My organisation</mat-label>
    </button>
  </a>
  <a routerLink="/service-request/list/inquiries">
    <button mat-menu-item>
      <mat-icon>assignment</mat-icon>
      <mat-label>My Matchmaking Dashboard</mat-label>
    </button>
  </a>
  <a>
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>Log Out
    </button>
  </a>
</mat-menu>