import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import { MobileServices } from 'src/app/core/services/mobile-services.service';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { RampAdminFacade } from 'src/app/core/components/navigation/ramp-admin.facade';
import { ViewChild, ElementRef } from '@angular/core';
import { SnackComponent } from 'src/app/shared/common-ui/snack/snack.component';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RAMPAdministrator } from "src/app/core/utilities/check-roles.utility"
import { Location } from '@angular/common';
import { Menu, AdminToggle, AdminMenuList, MyRamp, MenuItem } from 'src/app/core/components/navigation/menu'
import { WINDOW } from 'src/app/core/components/navigation/window';
import { Inject } from '@angular/core';
import { ServiceRequestToolService } from "src/app/data/main-datasource/domain/service-request-tool/service-request-tool.service"
import { UpdatePublicNumber } from 'src/app/features/service-request-tool/list-public/get-public-data.service';














@Component({
  selector: 'core-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @ViewChild('inputid', { static: false }) inputIdRef: ElementRef;
 

  openCloseNav = this.mobileServices.isNavOpen("mainMenu");
  NumberOfIteration = 0;
  AdminIcons = "menu";
  deleteButtonPressed = false;

  validateUUIDFormat(control: FormControl): { [key: string]: any } | null {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    const isValid = uuidRegex.test(control.value)

    // Return error if the input is not a valid UUID format
    return isValid ? null : { invalidUUIDFormat: { value: control.value } };
  }


  idInput = new FormControl('', [Validators.required, this.validateUUIDFormat]);







  deleteButtonPressedfunction = () => {
    return this.deleteButtonPressed;
  }

  closeMobileMenuOnNavigationEnd() {
    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        this.mobileServices.closeAllWithDelay();
      }
    });
  }





  formGroup: FormGroup;
  constructor(
    @Inject(WINDOW) private window: Window,
    private rampAdminFacade: RampAdminFacade,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private mobileServices: MobileServices,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private location: Location,
    private serviceRequestToolService: ServiceRequestToolService,
    private updatePublicNumber:UpdatePublicNumber


  ) {
    this.formGroup = new FormGroup({
      idInput: this.idInput
    });
  }

  user: User;




  isViewMobile: boolean = this.mobileServices.isViewMobile;
  respondToViewChange = this.mobileServices.emitViewChange.subscribe((isViewMobile: boolean) => {
    this.isViewMobile = isViewMobile;
  })


  isViewTablet = this.mobileServices.isViewTablet;
  respondToViewChangeTablet = this.mobileServices.emitViewTablet.subscribe((isViewTablet: boolean) => {
    this.isViewTablet = isViewTablet;
    if (this.isViewTablet == true) {
      this.menusize();
    }
  })














  noAnimation: boolean = true;
  menuUserExpanded: boolean = false;

  ngAfterViewInit() {
    setTimeout(() => { this.noAnimation = false; }, 500)




  }



  adminToggle: MenuItem[] = new AdminToggle().menuList;
  adminMenuList: MenuItem[] = new AdminMenuList().menuList;
  menuList: MenuItem[] = new Menu().menuList;
  myRamp: MenuItem = new MyRamp().menuList[0];




  isUserRampAdmin = false;

  isUserRampAdminFunction(user: User) {
    if (user.realm_access_roles.includes(RAMPAdministrator)) {
      this.isUserRampAdmin = true;
      return
    }
    this.isUserRampAdmin = false;

  }


  loadUser() {
    this.authService.getUser().subscribe((user) => {
      this.user = user;
      this.isUserRampAdminFunction(user)
      
      if (user?.email && user.email != "") {
        if (!this.menuList.includes(this.myRamp)) {
          this.menuList.push(this.myRamp);
        }
      }
      console.log(this.menuList)



    });

  }

  toggleUserMenu() {
    this.menuUserExpanded = !this.menuUserExpanded;
    if (this.menuUserExpanded == false) return;
    this.menuList.forEach((el) => {
      el.expanded = false;
    });
  



  }









  customAction(RouterLink: string, event: Event) {


    if (RouterLink == undefined) return;

    if (RouterLink == "ramp-admin") {
      event.preventDefault();
      return
    }

    /*  if (RouterLink == "https://qlack.eu/") {
        event.preventDefault();
        window.open(RouterLink, '_blank');
        return
  
      }*/


    if ((/^popup-.*/).test(RouterLink)) {
      let modalName: string = RouterLink.split("-")[1];
      this.openModalfromMenu(modalName);
      event.preventDefault();
      return
    }

    this.router.navigate([RouterLink], { queryParamsHandling: 'preserve' });



  }
  inputControl: FormControl;
  resetForm() {
    this.formGroup.reset();
  }


  openModalfromMenu(modalName: string) {
    this.openModalOrganization = false;
    this.openModalComponent = false;
    if (modalName == "organization") {
      this.openModalOrganization = true;
      this.resetForm();

    }
    if (modalName == "component") {
      this.openModalComponent = true;
      this.resetForm();

    }

  }




  isWide = true;
  lockWide = true;



  menusize() {
    this.lockWide = !this.lockWide
    if (this.isViewTablet == true) {
      this.lockWide = false;
    }
    if (this.isViewMobile == true) {
      this.lockWide = true;
    }


    if (!this.lockWide) {
      this.isWide = false;
      return
    }
    this.isWide = true
  }

  restactive() {
    [this.menuList, this.adminMenuList].forEach((menuList) => {
      menuList.forEach(EL => {
        EL["expanded"] = false;
        EL["active"] = false;
        EL["submenuList"].forEach(subEl => {
          subEl["active"] = false;
        })
      });
    })

  }


  CustomsetActive(categoryName, subCategoryName) {

    [this.menuList, this.adminMenuList].forEach((menuList) => {
      menuList.forEach(EL => {
        EL["expanded"] = false;
        EL["active"] = false;
        EL["submenuList"].forEach(subEl => {
          subEl["active"] = false;
        })

        if (EL["name"] == categoryName) {

          EL["expanded"] = true
          EL["active"] = true
          EL["submenuList"].forEach(subEl => {
            if (subEl["name"] == subCategoryName) {
              subEl["active"] = true
            }
          })
        }

      })

    })




  }

  ditectSubmenu() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.ifPageIframe()
        this.restactive()
        let url: string = event.urlAfterRedirects;     
        switch (true) {
          //Connect---------------------------
          case url == "/service-request/list/admin-inquiries":
          
          this.CustomsetActive("Service Requests", "Service Requests")
          break;
        case url == "/service-request/list/admin-dispute":
          this.CustomsetActive("Service Requests", "Negotiations Dispute")
          break;
          
          case url == "/service-request/ask-for-service":
            this.CustomsetActive("Connect", "Ask for a Service")
            break;

          case url == "/innovation-hub":
            this.CustomsetActive("Connect", "Get Local Support (DIHs)")
            break;

          case url == "/company/catalogue":
          case /\/company\/[a-zA-Z0-9]+(?!\/.*)/.test(url):
            this.CustomsetActive("Connect", "Find a Partner")
            break;
          case url == "/component/catalogue":
          case /\/component\/[a-zA-Z0-9]+(?!\/.*)/.test(url):
            this.CustomsetActive("Connect", "Discover Solutions")
            break;

        
          //Collaborate--------------------------------

          case url == "/discussion-board":
            this.window.open('https://ramp-forum.eurodyn.com/', '_blank');
            this.CustomsetActive("Collaborate", "Discussion board")
            break;
            case url == "/event/event-page-list":
              this.CustomsetActive("Collaborate", "News & Events")
              break;
              case url == "/collaborate/success-stories":
                this.CustomsetActive("Collaborate", "Success Stories")
                break;
                case url == "/service-request/list-public":
                  this.CustomsetActive("Collaborate", "Matchmaking Requests")
                  break;

          case url == "/ramp-iot":
            this.CustomsetActive("Automate", "RAMP IIoT Platform")
            break;

          //Automate ------------------------------------------
          case url == "/web-workspace/learnmore/1":
            this.CustomsetActive("Automate", "AppHub")
            break;
          case url == "/web-workspace/app/1":
            this.CustomsetActive("Automate", "AppHub")
            this.window.open('https://workspace.ramp.eu/', '_blank');
          break;
            
          case url == "/automate/docker-repository":
            this.CustomsetActive("Automate", "Docker Repository")
            break;
          case url == "/automate/cloud-data-storage":
            this.CustomsetActive("Automate", "Cloud Data Storage")
            break;
            case url == "/automate/data-visualisation":
              this.CustomsetActive("Automate", "Data Visualisation")
              break;
       
          //Learn about ------------------------------------------
          case url == "/learn-about/marketplace":
            this.CustomsetActive("Learn about", "Marketplace")
            break;
          case url == "/learn-about/access-the-ramp-iiot":
            this.CustomsetActive("Learn about", "Access The RAMP IIoT")
            break;


          //My RAMP------------------------------------------
          case url == "/memberships":
            this.CustomsetActive("My RAMP", "My Organisations")
            break;
          case url == "/service-request/list/inquiries":
            this.CustomsetActive("My RAMP", "My Matchmaking Dashboard")
            break;
           

          case /\/service-request\//.test(url):
           this.CustomsetActive("My RAMP", "My Matchmaking Dashboard")
            break;
            case /\/service-inquiry\//.test(url):
              this.CustomsetActive("My RAMP", "My Matchmaking Dashboard")
               break;
    
          case url == "/dashboard/app":
            this.CustomsetActive("My RAMP", "My Data Visualisations")
            break;
          case url == "/web-workspace/learnmore/2":        
            this.CustomsetActive("My RAMP", "My AppHub")
            break;
            case url == "/web-workspace/app/2":
              this.window.open('https://workspace.ramp.eu/', '_blank');
              this.CustomsetActive("My RAMP", "My AppHub")
              break;



          //Tools--------------------------------------------------
          //Dashboard
          case url == "/dashboard/app":
          case url == "/dashboard/learnmore":
            this.CustomsetActive("Tools", "Dashboard")
            break;
          //Digitization Audit
          case url == "/digitization_audit":
            this.CustomsetActive("Tools", "Digitization Audit")
            break;
          case url == "/organization-verification/requests-list/requests":
            this.CustomsetActive("Verify organization", "Verification requests")
            break;
          case url == "/organization-verification/requests-list/Verified":
            this.CustomsetActive("Verify organization", "Verified list")
            break;
        
     
          //event-page-list
    

          //event/proposed-list
          case url == "/event/proposed-list":
            this.CustomsetActive("Event submissions", "")
            break;


        }


      }
    });
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  noPad = false;
  ifPageIframe() {
    this.noPad = false;
    if (this.location.path() == "/web-workspace/learnmore") {
      this.noPad = true;
    }
    if (this.location.path() == "/home") {
      this.noPad = true;
    }




  }




  ngOnInit() {
    this.getUserServiceInquiryListPublic();

    this.ditectSubmenu();
    this.mobileServices.openCloseNav.subscribe((navObj) => {
      this.openCloseNav = this.mobileServices.isNavOpen("mainMenu");
    });
    this.loadUser();
    this.closeMobileMenuOnNavigationEnd()

  }

  toggle(menuList, index: number) {


    menuList.forEach((menu, loopindex) => {
      if (loopindex == index) {
        menu.expanded = !menu.expanded;

      } else {
        menu.expanded = false;
      }

    });
    let routerLink = menuList[index].parentRouterLink;
    this.toggleOpenAdminMenu(routerLink, menuList, index)


  }
  toggleOpenAdminMenu(RouterLink, menuList, index) {

    if (RouterLink == "ramp-admin") {
      this.openAdminMenu = menuList[index].expanded;
      this.AdminIcons = this.openAdminMenu ? "close" : "menu";

    }
  }
  openAdminMenu = false;



  redirectToSSO() {
    this.authService.login();
  }


  logout() {
    this.authService.logout();
  }
  openModalOrganization = false;
  openModalComponent = false;

  deleteOrganizationText = {
    text: "Delete Organization",
    placeholder: "Organization",
    openModal: this.openModalOrganization,
    id: "",
    link: "/company/edit/",

  };
  deleteComponentText = {
    text: "Delete Component",
    placeholder: "Component",
    openModal: this.openModalComponent,
    id: "",
    link: "/component/edit/"
  };

  modalWasClosedEvent(isModalOpen: boolean): void {
    this.openModalOrganization = isModalOpen;
    this.openModalComponent = isModalOpen;
    this.openDelete = false;


  }


  //'warn-snackbar'

  SnackbarProp = {
    duration: 2000,
    verticalPosition: 'top' as MatSnackBarVerticalPosition,
    panelClass: ['success-snackbar'],
    data: {
      message: ``,
      icon: 'done',
    },
  }



  errorSnackbar(id, type) {
    this.SnackbarProp.data.message = `${type} with ID:${id} Does not exist`;
    this.SnackbarProp.panelClass = ['warn-snackbar'];
    this._snackBar.openFromComponent(SnackComponent, this.SnackbarProp);

  }


  successSnackbar(id, type) {
    this.inputIdRef.nativeElement.value = "";
    this.SnackbarProp.data.message = `${type} with ID:${id} has been deleted`;
    this._snackBar.openFromComponent(SnackComponent, this.SnackbarProp);
  }


  openDelete = false;
  predeletefunction() {

    this.formGroup.markAllAsTouched();


    if (this.formGroup.get('idInput').valid == false) {
      return
    }
    this.openDelete = true;




  }
  cancelfunction() {
    this.openDelete = false;

  }

  editfunction(link, inputValue) {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.get('idInput').valid == false) {
      return
    }
    this.router.navigate([link + "/" + inputValue]);

  }

  deletefunction(data, inputValue) {


    let value = inputValue.trim();
    if (data.placeholder == "Organization") {
      this.deleteOrganization(value, "Organization");
    } else {
      this.deleteComponent(value, "Component");
    }


  }


  async deleteComponent(ComponentID: string, type: string) {


    if (await this.authService.isTokenValidandUserLoggedIn() == false) {
      this.authService.logout();
      return
    }


    this.rampAdminFacade.deleteComponent(ComponentID).subscribe({
      next: () => {
        this.successSnackbar(ComponentID, type);
        this.deleteButtonPressed = false;

      }, error: (err) => {
        this.errorSnackbar(ComponentID, type)

      }

    });
  }
  async deleteOrganization(organizationID, type: string) {
    if (await this.authService.isTokenValidandUserLoggedIn() == false) {
      this.authService.logout();
      return
    }


    this.rampAdminFacade.deleteOrganization(organizationID).subscribe({
      next: () => {
        this.successSnackbar(organizationID, type);
        this.deleteButtonPressed = false;

      },
      error: (err) => {
        this.errorSnackbar(organizationID, type)
      },
    });

  }
 openPublicMatchmakingRequest:number=0;
  getUserServiceInquiryListPublic() {
     this.serviceRequestToolService.getUserServiceInquiryPublicList().subscribe({
      next: (InquiryListListData) => {
        
        let publiclen = InquiryListListData.filter(item => item.acceptOfferStatusEnum === "OPEN").length;
        this.updatePublicNumber.updateNumber(publiclen);
       
         



      },
      error: (err) => {
        console.log(err);
      },
    });
    this.updatePublicNumber.currentNumber.subscribe(number => this.openPublicMatchmakingRequest = number);
  }













}
