import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/auth.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class MainHeadersService {
  constructor(private authService:AuthService, private keycloackService: KeycloakService,) { }
  HTTP_CLIENT_HEADERS = ()=>{ 
    let token = this.keycloackService.getKeycloakInstance().token;
    let HTTP_CLIENT_HEADERS = new HttpHeaders().set('Accept', 'application/json'); 
    if(token!=undefined){
      return HTTP_CLIENT_HEADERS.set('Authorization', 'Bearer ' + token);
    }else{
      return  HTTP_CLIENT_HEADERS;
    }
   
  }

  


  
  

 HTTP_CLIENT_HEADERS_WITH_BEARER: (token: string|false) => HttpHeaders = (token: string) => {
  this.keycloackService.getKeycloakInstance().token;
    return this.HTTP_CLIENT_HEADERS().set('Authorization', 'Bearer ' + token);
  };


}
