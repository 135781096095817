import { Injectable, EventEmitter } from '@angular/core';
import { HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SlidingPages } from "src/app/core/models/mobileSlidingPages.model"



@Injectable({
  providedIn: 'root'
})
export class MobileServices {
  emitViewChange = new EventEmitter<boolean>();
  emitViewTablet = new EventEmitter<boolean>();
  sideNavEvent = new EventEmitter<boolean>();



  SlidingpagesArr: SlidingPages[] = [
    { isOpen: false, slideName: "mainMenu" },   
    { isOpen: false, slideName: "SelectedComponents" },
    { isOpen: false, slideName: "companyFilters" },
    { isOpen: false, slideName: "componentFilters" },
    { isOpen: false, slideName: "chatboxConsumer" },
    { isOpen: false, slideName: "conflictResolution" }
    
  ]
  constructor() { }

  openCloseNavSubject = new BehaviorSubject<SlidingPages[]>(this.SlidingpagesArr);
  openCloseNav = this.openCloseNavSubject.asObservable();

  isViewMobile = false;
  isViewTablet = false;


  breakPointMobile = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--break-point-mobile').replace("px", ""));
  breakPointTablet = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--break-point-tablet').replace("px", ""));

  ViewMobileCheck() {


    this.isViewMobile = false;
    if (window.innerWidth <= this.breakPointMobile) {
      this.isViewMobile = true;
    }
    this.emitViewChange.emit(this.isViewMobile);

    
      
    this.isViewTablet = false;
    if (window.innerWidth < this.breakPointTablet) {
      this.isViewTablet = true;
    }
    this.emitViewTablet.emit(this.isViewTablet);
    
    

  }



  @HostListener('window:resize', ['$event'])
  onResize() {
    this.ViewMobileCheck();
  }
  onloadPage() {
     this.ViewMobileCheck();
  }

  renewSlidingPagesArr(navObjNew: SlidingPages) {       
    let newSlidingpagesArr = [];
    
    this.SlidingpagesArr.forEach((navObj: SlidingPages) => {   
      if (navObj.slideName == navObjNew.slideName) {
        newSlidingpagesArr.push(navObjNew);
     
      } else {
        navObj.isOpen=false;
        newSlidingpagesArr.push(navObj);
      }
    });
    this.SlidingpagesArr = newSlidingpagesArr;
    
    this.openCloseNavSubject.next(this.SlidingpagesArr);

  }

openNav(navObjNew: SlidingPages) { 
  this.renewSlidingPagesArr(navObjNew);

}

  toggleNav(navObjNew: SlidingPages) {   
    if(this.isNavOpenMobile(this.SlidingpagesArr)){
      this.closeAll()
    }else{
    navObjNew.isOpen = !navObjNew.isOpen;    
    this.renewSlidingPagesArr(navObjNew);
    }
  }


  isNavOpenMobile(navObjArr: SlidingPages[]): boolean {
    let isOpen = navObjArr.find((item: SlidingPages) => { return item.isOpen == true });
    return isOpen != undefined ? true : false;
  }
  
  isNavOpen(elName: string): boolean {
    let navEL = this.SlidingpagesArr.find((item: SlidingPages) => { return item.slideName == elName }); 
    return navEL.isOpen
  }


  closeAll(){
    this.SlidingpagesArr.forEach((el)=>{
      el.isOpen=false;
    });    
    this.openCloseNavSubject.next(this.SlidingpagesArr);
  }

  closeAllWithDelay(){
  if(this.isViewMobile==true){ 
    document.documentElement.scrollTop = 0;
    setTimeout(() => {
      this.closeAll();
   
    },500) 
  }   
   
  }




}
