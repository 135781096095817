import {BehaviorSubject, Observable,Subject, Subscription} from 'rxjs';
import {Injectable} from '@angular/core';
import {SoftComponent} from './softcomponent.model';
import {SoftcomponentService} from './softcomponent.service';
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core'


@Injectable({providedIn: 'root'})
export class CatalogueFacade {
  private noneComponent(): SoftComponent {
    return {
      componentId: 'none',
      companyId: 'none',
      summary: 'none',
      description: 'none',
      mainPicture: 'none',
      photos: 'none',
      applicableIndustries: [],
      documentation: 'none',
      interfacesDataInputText: 'none',
      interfacesDataOutputText: 'none',
      keyHardwareSoftwareDependencies: 'none',
      SelectedByUser:false,
      componentsArray:[],
  
    }
  }
 

  componentList:SoftComponent[]= [];
  UserSelectedComponents:SoftComponent[] = [];

  industriesList:string[]=[
    
  ];

 
  


  licenses:string[]=[
    "Public domain (CC0, Unlicensed, etc.)",
    "Permissive (MIT, Apache, MPL, etc.)",
    "Copyleft (GPL, AGPL, etc.)",
    "Noncommercial (JRL, AFPL, etc.)",
    "Proprietary"
      ];
    
  
 
  
  resetKeywordInput = new EventEmitter();
  allkeywords = new Subject<string[]>();
  industriesListSubject$ = new Subject<string[]>(); 
  componentListSubject = new BehaviorSubject<{}>(this.componentList);
  private activeComponentSubject = new BehaviorSubject<SoftComponent>(this.noneComponent());
  UserSelectedComponents$ = new BehaviorSubject<SoftComponent[]>(this.UserSelectedComponents);







  componentList$ = this.componentListSubject.asObservable();
  activeComponent$ = this.activeComponentSubject.asObservable();

  constructor(private softcomponentService: SoftcomponentService,private http: HttpClient) {

  }

 



  resetKeywordInputFn(){
    this.resetKeywordInput.emit();

  }



  onLoadAddselected(){   
    this.componentListSubject.subscribe(
      (data:any) => {
   
        data.forEach((el)=>{          
          if(el.SelectedByUser==undefined){
            el.SelectedByUser=false;
  
          }
        })
        this.UserSelectedComponents.forEach((el)=>{
          if(el.componentId==data.componentId){          
            el.SelectedByUser=true;  
          }
        })
        
        this.UserSelectedComponents$.next(this.UserSelectedComponents);
       
        
        
        



      })
    }

 loadKeyWords():void{
  this.softcomponentService.loadKeyWords().subscribe({
    next: (KeyWordslist) => {     
      this.allkeywords.next(KeyWordslist); 
      
  
    },
    error: (err) => {
      console.error('err', err);
    },
  });

 }


  loadIndustries():Subscription{
   return this.softcomponentService.loadIndustries().subscribe({
      next: (industriesList) => {        
        this.industriesList = industriesList.map((el)=>{
          return el["name"];
        });  
        
        
        this.industriesListSubject$.next(this.industriesList);
        
        
        //(this.industriesList);
      },
      error: (err) => {
        console.error('err', err);
      },
    });




  }

  loadSupportWorkers():Observable<string[]>{
    return this.softcomponentService.loadSupportWorkers();
  };

  loadSupportTasks():Observable<string[]>{
   return this.softcomponentService.loadSupportTasks();
  };



  load(): void {
    this.softcomponentService.load().subscribe({
      next: (componentList) => {       
    
        this.componentListSubject.next(componentList);
      },
      error: (err) => {
        console.error('err', err);
      },
    });
  }

  byid(id: string): Observable<SoftComponent> {
    this.softcomponentService.byid(id).subscribe({
      next: (active) => {
        this.activeComponentSubject.next(active);
        
      },
      error: (err) => {
        console.error('err', err);
      },
    });
    return this.softcomponentService.byid(id);
  }

  byCompanyId(companyid: string): void {
    this.softcomponentService.byCompanyId(companyid).subscribe({
      next: (componentList) => {
        this.componentListSubject.next(componentList);
      },
      error: (err) => {
        console.error('err', err);
      },
    });
  }

  create(component: SoftComponent): Observable<SoftComponent> {  
    return this.softcomponentService.create(component);
  }
  update(component: SoftComponent): Observable<SoftComponent> {
    return this.softcomponentService.update(component);
  }

  search(
    name: string,
    industryList: string,
    rampIotCompatible: boolean,
    RAMPpartner:boolean,
    licenses:string,
    keywords:string ,
    download:boolean,
    lastupdate:number,
    supportWorkers:string, 
    supportTasks:string,   
  ) {

   
    
   
    this.softcomponentService.search(name, industryList, rampIotCompatible,RAMPpartner,licenses,keywords,download,lastupdate,supportWorkers,supportTasks).subscribe({
      next: (companyList) => {
        this.onLoadAddselected();
        this.componentListSubject.next(companyList);
       

      },
      error: (err) => {
        console.error('err', err);
      },
    })
  }

  delete(componentId: string): Observable<SoftComponent> {
    return this.softcomponentService.delete(componentId);
  }
}
