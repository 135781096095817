import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { CustomScrollbarComponent } from './shared/custom-scrollbar/custom-scrollbar.component';













 



@NgModule({
  declarations: [
    AppComponent,
    CustomScrollbarComponent,
   

 
  ],
  imports: [
    NgxMatomoTrackerModule.forRoot({
      siteId: '1',
      trackerUrl: window.location.hostname == "localhost"? null:'https://ramp.eu/matomo', 
      //trackerUrl: 'http://ramp-beta-server.eurodyn.com:18080/',
      //trackerUrl: 'http://ramp-beta-server.eurodyn.com:18080/matomo.php',
      //scriptUrl: 'http://ramp-beta-server.eurodyn.com:18080/matomo.js',
      //trackerUrl: 'http://ramp-beta-server.eurodyn.com:18080',
      //trackerUrl: 'matomo-deployment_matomo_1:80',
      //trackerUrl: 'http://localhost:18080',
    }),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMatomoRouterModule,
   
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
 
  
  

}
