import { GroupService } from './services/group.service';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from '../shared/material/material.module';
import { RouterModule } from '@angular/router';
import { AuthService } from './services/auth.service';
import { SortByNamePipe } from './pipes/sort-by-name.pipe';
import { FooterNavComponent } from './components/mobile/footer-nav/footer-nav.component';
import { slidePageModule } from 'src/app/core/components/mobile/slide-page/slide-page.module';
import { ModalModule } from 'src/app/features/component/ui-components/modal/modal.module';
import { windowProvider } from 'src/app/core/components/navigation/window';



@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    slidePageModule,
    ModalModule,
    ReactiveFormsModule,
  ],
  declarations: [
    NavigationComponent,
    FooterComponent,
    HeaderComponent,
    SortByNamePipe,
    FooterNavComponent,
    
      
  ],
  exports: [
    NavigationComponent,
    FooterComponent,
    HeaderComponent,
    SortByNamePipe,
    FooterNavComponent,  
  ],
  providers: [
    AuthService,
    GroupService,
    windowProvider
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
