import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";

export function initializeKeycloak(
    keycloak: KeycloakService
) {
    return () =>
        keycloak.init({
            config: {
                url: environment.keycloakBaseUrl,
                realm: environment.keycloakRealm,
                clientId: environment.keycloakClient,
            },
            initOptions: {
                onLoad: 'check-sso',
                checkLoginIframe: false,
                // silentCheckSsoRedirectUri:
                //   window.location.origin + '/assets/silent-check-sso.html',
            },
            loadUserProfileAtStartUp: true,
            //Exclude URL's that start with /api/v1/ And after v1/ Does not contain user
            bearerExcludedUrls: ['^(?!.*\/user\/).*$'],
        });
}
