import { Component, EventEmitter, OnInit, Output, Renderer2 ,AfterViewInit} from '@angular/core';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { MobileServices } from 'src/app/core/services/mobile-services.service';
import { SlidingPages } from "src/app/core/models/mobileSlidingPages.model"





@Component({
  selector: 'core-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
  SlidingpagesArr=this.mobileServices.SlidingpagesArr
  isNavOpen:boolean=this.mobileServices.isNavOpenMobile(this.SlidingpagesArr); 
  @Output() sideNavEvent = new EventEmitter<boolean>();
  logoImageUrl = "./assets/logo/ramp_logo_white.png";
  body=this.renderer.selectRootElement('body',true);


  constructor(
    private authService: AuthService,
    private mobileServices: MobileServices,
    private renderer: Renderer2,
  
    ) { }


  isViewMobile:boolean=this.mobileServices.isViewMobile;


  user:User= new User();
  open:false;
  respondToViewChange=this.mobileServices.emitViewChange.subscribe((isViewMobile:boolean)=>{
    this.isViewMobile=isViewMobile;
  })

  ngOnInit() {   
    
    this.authService.refreshAuth();
    this.load();
    this.onEmitNavCheinge();
    
    
 
   
  
  }

  ngAfterViewInit(){
 
    this.AddClassToBody() 

  }

  async load() { 
     this.authService.getUser().subscribe((userInfo)=>{      
      this.user = userInfo;
    });
  
    
  }

  redirectToSSO() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }


 

  onNavCheinge(navName:string) {
      let SlidingpagesEl = this.SlidingpagesArr.find((item: SlidingPages) => { return item.slideName == navName });   
        this.mobileServices.toggleNav(SlidingpagesEl);

    
   
  } 
  onEmitNavCheinge(){
    this.mobileServices.openCloseNav.subscribe((navArr:SlidingPages[])=>{       
      this.isNavOpen=this.mobileServices.isNavOpenMobile(navArr);
      this.AddClassToBody()       
    });
}


AddClassToBody(){ 
  if(this.isViewMobile==false){
   return
  }
   if(this.isNavOpen==true){
    this.renderer.addClass(this.body, 'slideOpen');

   }else{
    this.renderer.removeClass(this.body, 'slideOpen');

   }
 }



}
