import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from 'src/app/features/component/ui-components/modal/modal.component'
import { MaterialModule } from 'src/app/shared/material/material.module';



@NgModule({
  declarations: [ModalComponent],
  exports: [ModalComponent],
  imports: [
    CommonModule,
    MaterialModule
  ]
})
export class ModalModule { }
