<div class="toolbar">
  <div class="dataEl">
    <div class="grid">

      <div class="link23" routerLink='/aboutus'>
        <mat-icon>group</mat-icon>About us
      </div>
      <div class="link23" routerLink='/contactus'>
        <mat-icon>alternate_email</mat-icon>Contact us
      </div>
      <div class="link23" routerLink='/privacy-notice'>
        <mat-icon>privacy_tip</mat-icon>Privacy notice
      </div>
      <div class="link23" (click)="openNewTab('/assets/legal/RAMP_user_agreement.pdf')">
        <mat-icon>gavel</mat-icon>
        <mat-label>User Agreement</mat-label>
      </div>
    </div>
    <div class="funding">
      <img src="./assets/images/footer/Logo_EC.jpg" /> This project has received funding from the European Union’s
      Horizon 2020 research and innovation programme under grant agreements No 767642, No 824964, No 873087, No 951813
      and No 952119
    </div>
  </div>
  

</div>
<div class="date23">© 2017-2024 Robotics and Automation MarketPlace (RAMP.eu)</div>
