export class CheckIfUserOrRampAdminModel {
    isUserRampAdmin: boolean = false;
    isUserEditOrDelete: boolean = false;
  }

export let RAMPAdministrator = "RAMP-admin";

  
function isUserEditOrDeletefunction(user, companyId:String,userRoles:string[]):boolean  {
    

    let userIsMemeber = user.memberof;
   
    let foundCompanyId = false;
    let foundKeyword = false;
      
    for (let i = 0; i < userIsMemeber.length; i++) {
      if (userIsMemeber[i].includes(companyId)) {
        foundCompanyId = true;
        if (foundKeyword) {
          break; // stop the loop when both companyId and at least one keyword are found
        }
      }
      if (foundCompanyId == true) {
        for (let j = 0; j < userRoles.length; j++) {
          if (userIsMemeber[i].includes(userRoles[j])) {
            foundKeyword = true;
            if (foundCompanyId) {
              break; // stop the loop when both companyId and at least one keyword are found
            }
          }
        }
      }
  
    } 
  
    return foundCompanyId && foundKeyword;  
  }

  export function IsUserRampAdmin(user) {
    if (user.realm_access_roles.includes(RAMPAdministrator)) { return true; }
    return false;
  }
  

  export function CheckUserRolesObj(user, companyId,userRoles:string[]) {
    let Obj:CheckIfUserOrRampAdminModel=new CheckIfUserOrRampAdminModel()  
    Obj.isUserEditOrDelete = isUserEditOrDeletefunction(user, companyId, userRoles);
    Obj.isUserRampAdmin = IsUserRampAdmin(user);
    return Obj;  
  }

  