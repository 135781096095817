
export function EncodeUriUtility (value: string|any[],key:string|undefined): string { 
 if(value==null){ return ""};
  let newvalue:string="";
  [...value].forEach((el,index)=>{
    if(key==""||undefined){
    newvalue+=encodeURIComponent(el);
    }else{
      newvalue+=encodeURIComponent(el[key]);
    }
    if (index != [...value].length - 1){
      newvalue+=",";        
  }      
  });    
  
  return newvalue; 
}
