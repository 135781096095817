import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('src/app/features/homepage/homepage.module').then(m => m.HomepageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/features/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'discussion-board',
    loadChildren: () => import('src/app/features/discussion-board/discussion-board.module').then(m => m.DiscussionBoardModule)
  },
  {
    path: 'web-workspace',
    loadChildren: () => import('src/app/features/web-workspace/web-workspace.module').then(m => m.WebWorkspaceModule)
  },
  {
    path: 'memberships',
    loadChildren: () => import('src/app/features/memberships/memberships.module').then(m => m.MembershipsModule)
  },
  {
    path: 'company',
    loadChildren: () => import('src/app/features/company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'softwarelibrary',
    loadChildren: () => import('src/app/features/software-library/software-library.module').then(m => m.SoftwareLibraryModule)
  },
  {
    path: 'component',
    loadChildren: () => import('src/app/features/component/component.module').then(m => m.ComponentModule)
  },
  {
    path: 'digitization_audit',
    loadChildren: () => import('src/app/features/digitization-audit/digitization-audit.module').then(m => m.DigitizationAuditModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('src/app/features/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('src/app/features/aboutus/aboutus.module').then(m => m.AboutusModule)
  },
  
  {
    path: 'privacy-notice',
    loadChildren: () => import('src/app/features/privacy-notice/privacy-notice.module').then(m => m.PrivacyNoticeModule)
  },

  {
    path: 'news',
    loadChildren: () => import('src/app/features/news/news.module').then(m => m.newsModule)
  },

  {
    path: 'contactus',
    loadChildren: () => import('src/app/features/contactus/contactus.module').then(m => m.ContactusModule)
  },
  {
    path: 'status',
    loadChildren: () => import('src/app/features/status-pages/status-pages.module').then(m => m.StatusPagesModule)
  },
  {
    path: 'innovation-hub',
    loadChildren: () => import('src/app/features/innovation-hub/innovation-hub.module').then(m => m.InnovationHubModule)
  },
  {
    path: 'ramp-iot',
    loadChildren: () => import('src/app/features/ramp-iot/ramp-iot.module').then(m => m.PartnersModule)
  },

  {
    path: 'service-request',
    loadChildren: () => import('src/app/features/service-request-tool/service-request-tool.module').then(m => m.ServiceRequestToolModule)
  },
  {
    path: 'service-inquiry',
    loadChildren: () => import('src/app/features/service-inquiry/service-inquiry.module').then(m => m.ServiceInquiryModule)
  },
  {
    path: 'organization-verification',
    loadChildren: () => import('src/app/features/organization-verification/organization-verification.module').then(m => m.OrganizationVerificationModule)
  },
  {
    path: 'conflict-resolution',
    loadChildren: () => import('src/app/features/conflict-resolution/conflict-resolution-module.module').then(m => m.ConflictResolutionModuleModule)
  },
  {
    path: 'event',
    loadChildren: () => import('src/app/features/event/event.module').then(m => m.EventModule)
  },

  {
    path: 'need-to-register',
    loadChildren: () => import('src/app/features/need-to-register/need-to-register.module').then(m => m.NeedToRegisterModule)
  },
  {
  path: 'learn-about',
  loadChildren: () => import('src/app/features/learn-about/learn-about.module').then(m => m.LearnAboutModule )
  },
  {
  path: 'automate',
  loadChildren: () => import('src/app/features/automate/automate.module').then(m => m.AutomateModule )
  },
  {
    path: 'collaborate',
    loadChildren: () => import('src/app/features/collaborate/collaborate.module').then(m => m.CollaborateModule )
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
