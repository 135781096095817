import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MobileServices } from 'src/app/core/services/mobile-services.service';
import { CatalogueFacade } from 'src/app/data/main-datasource/domain/softcomponent/softcomponent.facade';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mobile-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})


export class FooterNavComponent implements OnInit {
  constructor(private router: Router, private MobileServices: MobileServices, private catalogueFacade: CatalogueFacade,) { }
  buttons: { name: string, icon: string, number: number, link: string }[] = [];
  ComponentListLength = 0
  url:string; 
  respondToViewChange: Subscription;





  SelectedComponents = { name: "Selected Components", icon: "add_shopping_cart", link: "mainMenu2", number: this.ComponentListLength };






  NavigationEndFunctions(event) {
    this.url = event.urlAfterRedirects;
    this.getNewFooterLinks();

  }

  getNewFooterLinks() {
    const uuidPattern = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
  const regex = new RegExp(`/conflict-resolution/${uuidPattern}`);
 
    
    switch (true) {
 

      case (regex.test(this.url)):
              
              this.buttons = [
                { name: "chat", icon: "chat", link: "conflictResolution", number: 0 },           
              ]; 
            break; 
      case this.url == "/component/catalogue":
        this.buttons = [
          { name: "filters", icon: "filter_list", link: "componentFilters", number: 0 },
          { name: "Components", icon: "add_shopping_cart", link: "SelectedComponents", number: 0 }
        ];

        break;
        case this.url == "/company/catalogue":
          this.buttons = [
            { name: "filters", icon: "filter_list", link: "companyFilters", number: 0 },           
          ];  
          break;
          
          case this.url.includes('/service-request/consumer/'):
          case this.url.includes('/service-request/consumer/'):
           case this.url.includes('/service-request/provider/'):
            case this.url.includes('/service-inquiry/'):
             
            this.buttons = [
              { name: "chat", icon: "chat", link: "chatboxConsumer", number: 0 },           
            ];  
            break;
            
            
        
      default:
        this.buttons = [];
    }


  };


  updateArray(){
    this.buttons.forEach((el)=>{
      if(el.link=="SelectedComponents"){
        el.number=this.ComponentListLength;
      }
    })

  }


  SelectedComponentsByUserLength(){    
    this.catalogueFacade.UserSelectedComponents$.subscribe((ComponentList) => {
      this.ComponentListLength = ComponentList.length;
      this.updateArray()
    
    });

    
  }

initNav(){
  this.SelectedComponentsByUserLength();
  this.router.events.subscribe(event => {
    if (event instanceof NavigationStart) {

  

    }
    if (event instanceof NavigationEnd) {
      this.NavigationEndFunctions(event);
    }
  });

}


respondToViewChangeFunction(){  
  this.respondToViewChange=this.MobileServices.emitViewChange.subscribe((isViewMobile:boolean)=>{
    if(isViewMobile==true){    
      this.url = this.router.url;
      this.getNewFooterLinks();
    };
  })
  }




  ngOnInit() {    
    this.initNav();
    this.respondToViewChangeFunction();
  }

  navOpen(navName) {        
    let navEl = { isOpen: true, slideName: navName };    
    this.MobileServices.openNav(navEl);

  }
}
