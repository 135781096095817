export class User {
    sub: string = '';
    email_verified?: boolean;
    name: string = '';
    memberof?: string[] = [];
    preferred_username: string = '';
    given_name: string = '';
    family_name: string = '';
    email: string = '';
    realm_access_roles: string[] = [];
  
   
  }

  export class EditProfile {
    email: string="";
    name: string="";
    last_name: string="";
   
   
  
   
  }