import { NavigationStart, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MobileServices } from 'src/app/core/services/mobile-services.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'RAMP';
 

  constructor(
    private router: Router,
    private mobileServices:MobileServices
  ){}

  isViewMobile:boolean=this.mobileServices.isViewMobile;
  respondToViewChange=this.mobileServices.emitViewChange.subscribe((isViewMobile:boolean)=>{
    this.isViewMobile=isViewMobile;
  })

  windowResize(){
    this.mobileServices.onResize();
       
  }
  isHomePage=false;





  

 



  ngOnInit() {
    this.router.events.subscribe(event => {
     

      if (event instanceof NavigationStart) {
        this.isHomePage = (event.url=="/home") ? true : false;
        if (!!event.url && event.url.match(/^\/#/)) {
          let url = '/#'+event.url;
        
          this.router.navigate([url]);
        }
      }
    });
    this.windowResize();
  


  }

}
