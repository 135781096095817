// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keycloakBaseUrl: window["env"]["keycloakBaseUrl"] || "http://localhost:5100/auth",
  keycloakRealm: window["env"]["keycloakRealm"] || "ramp",
  keycloakClient: window["env"]["keycloakClient"] || "ramp-ui",

  supersetAppUrl: window["env"]["supersetAppUrl"] || "http://localhost:8088",
  apiRootUrl: window["env"]["apiRootUrl"] || "http://localhost:8081",
  keycloakApiRootUrl: window["env"]["keycloakApiRootUrl"] || "http://localhost:8082",
  invitationApiRootUrl: window["env"]["invitationApiRootUrl"] || "http://localhost:8083",
  componentApiRootUrl: window["env"]["componentApiRootUrl"] || "http://localhost:8085",


  digitizationAuditUrl: window["env"]["digitizationAuditUrl"] || "http://localhost:10006/mfe/digitization_audit/index.html"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
