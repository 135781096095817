
<div (click)="closeModal($event)" class="modal-bg closeModal" [ngClass]="{'open': openModal}">
    <div class="modal-body">      
        <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>        
        <mat-icon (click)="closeModal($event)" class="closeModal">close</mat-icon>
    </div>
</div>



