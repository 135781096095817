import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  

  constructor() { }

  ngOnInit() {
  }

  openNewTab(url: string) {
    window.open(url, '_blank');
  }

}
