import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { MobileServices } from 'src/app/core/services/mobile-services.service';

@Component({
  selector: 'slide-page',
  templateUrl: './slide-page.component.html',
  styleUrls: ['./slide-page.component.scss']
})
export class SlidePageComponent implements OnInit {
  openCloseNav: boolean;


  constructor(
    private mobileServices: MobileServices
  ) { }


  @Input() navTemplate: TemplateRef<any>;
  @Input() slideName: string;

  ngOnInit(): void {
  
    this.openCloseNav = this.mobileServices.isNavOpen(this.slideName);
  
    this.mobileServices.openCloseNav.subscribe(() => {
      
  
      this.openCloseNav = this.mobileServices.isNavOpen(this.slideName);
    
      if(this.openCloseNav==true){
        document.querySelectorAll(".page-parent").forEach((el)=>{
          el.scrollTop=0;
        })
      }
    });


  }









}
