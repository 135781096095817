

import { Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import { CompanyService } from 'src/app/data/main-datasource/domain/company/company.service';
import {SoftcomponentService} from 'src/app/data/main-datasource/domain/softcomponent/softcomponent.service';
import {SoftComponent} from 'src/app/data/main-datasource/domain/softcomponent/softcomponent.model';
import { Company } from 'src/app/data/main-datasource/domain/company/company.model';



@Injectable({ providedIn: 'root' })
export class RampAdminFacade {
    constructor(private companyService: CompanyService,private softcomponentService: SoftcomponentService,) { }

    deleteOrganization(id: string): Observable<Company> {      
        return this.companyService.delete(id)
    }
    deleteComponent(componentId: string): Observable<SoftComponent> {
        return this.softcomponentService.delete(componentId);
      }


}
