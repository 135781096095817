import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

interface snackData {
  message: string,
  icon: string,
}

@Component({
  selector: 'app-snack',
  templateUrl: './snack.component.html',
  styleUrls: ['./snack.component.scss']
})
export class SnackComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: snackData) { }

  ngOnInit(): void {
  }

}
