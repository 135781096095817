import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from "../../../../../environments/environment";
import { mainApiRoutes } from "../../config/main.apiroutes";
//import {HTTP_CLIENT_HEADERS,HTTP_CLIENT_HEADERS_WITH_BEARER} from "../../config/main.headers";
import { MainHeadersService } from 'src/app/data/main-datasource/config/main-headers.service';
import { HttpClient } from "@angular/common/http";
import { ServiceRequest } from 'src/app/features/service-request-tool/helper-functions/service-request.model';
import { SoftComponent } from 'src/app/data/main-datasource/domain/softcomponent/softcomponent.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ServiceInquiryCreate, ServiceInquiryObject } from 'src/app/features/service-request-tool/helper-functions/service-request.model';
import { HttpParams } from '@angular/common/http';
import { OfferCreateModel,OfferUpdateModel } from 'src/app/features/service-request-tool/offer-create.model';
import { CirculoosFormData } from 'src/app/features/service-inquiry/service-inquiry-form/circuloosForm.model';

@Injectable({
  providedIn: 'root'
}) 
export class ServiceRequestToolService {
  constructor(private http: HttpClient,
    private authService: AuthService, private mainHeadersService: MainHeadersService) {
  }
  //---------------------------------------------------------------------------------//
  getOrganizationName(organizationId): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT + organizationId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceRequest>(url, { headers });
  }

  getServiceRequestList(apiRoute:string): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes[apiRoute];
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceRequest>(url, { headers });

  }


  getUserServiceInquiryList(): Observable<ServiceInquiryObject[]> {
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_INQUIRY_GET_ALL;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceInquiryObject[]>(url, { headers });
  }
  getUserOfferList(): Observable<ServiceInquiryObject[]> {
    const url = environment.apiRootUrl + mainApiRoutes.GET_MY_OFFERS;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceInquiryObject[]>(url, { headers });
  }
  getUserServiceInquiryPublicList(): Observable<ServiceInquiryObject[]> {
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_INQUIRY_GET_ALL_PUBLIC;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceInquiryObject[]>(url, { headers });
  }
  //--------------------------------------------------------------------------------//
  getconsumerEmail(sid): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST_GET_CONSUMER_MAIL + sid;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceRequest>(url, { headers });
  }
  GetDataForChart(sid): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST_GET_ALL_NEEDS + sid;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceRequest>(url, { headers });
  }
  getPreSelectedComponents(sid): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST_PRE_SELECTED_COMPONENTS + sid;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceRequest>(url, { headers });
  }
  createServiceRequest(ServiceRequest: ServiceRequest): Observable<ServiceRequest> {
    let url:string;    
    if (ServiceRequest.parentPreServiceRequest!=null) {
      url = environment.apiRootUrl + mainApiRoutes.INQUIRY_SERVICE_REQUEST_CREATE_SERVICE_REQUEST;
    } else {
      url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST;
    }   
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.post<ServiceRequest>(url, ServiceRequest, { headers });
  }

  getServiceByid(id: string): Observable<ServiceRequest> { 
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST +"/"+id;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
  
    return this.http.get<ServiceRequest>(url, { headers });
  }
  getProviderComponents(): Observable<SoftComponent[]> {    
     let url: string;
      url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_LIST_ENDPOINT;

    
   
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<SoftComponent[]>(url, { headers });
  }

  updateProviderComponents(term:string): Observable<SoftComponent[]> { 
    let params = new HttpParams()
  .set('term', term)
  .set('rampIotCompatible', 'false')
  .set('rampPartner', 'false')
  .set('applicableIndustries', '')
  .set('download', 'false')
  .set('keywords', '')
  .set('lastUpdate', '26')
  .set('license', '')
  .set('supportWorkers', '')
  .set('supportTasks', '');  
 
    const url = environment.componentApiRootUrl + mainApiRoutes.COMPONENT_SEARCH_ENDPOINT;    
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<SoftComponent[]>(url, {params, headers});
 }
 
  updateServiceRequest(ServiceRequest: ServiceRequest): Observable<ServiceRequest> {
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.put<ServiceRequest>(url, ServiceRequest, { headers });
  }
  async getAccessToken() {
    let accessToken = await this.authService.getAccessToken();
    if (accessToken == false) {
      throw new Error("no access token");
    }
    return accessToken;
  }
  async uploadContract(contractPdf: any, serviceId: string): Promise<Observable<any>> {
    let accessToken = await this.authService.getAccessToken();
    const formData = new FormData();
    formData.append('file', contractPdf);
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST_UPLOAD_PDF + serviceId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS_WITH_BEARER(accessToken);
    return this.http.post<ServiceRequest>(url, formData, { headers, responseType: 'blob' as 'json' });
  }
  getUploadedFile(contractId: any): Observable<any> {   
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST_GET_FILE + contractId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceRequest>(url, { headers, responseType: 'blob' as 'json' });
  }
  getImageFile(id: string): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.COMPANY_SINGLE_ENDPOINT_FILE_DOWNLOAD + id;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<any>(url, { headers, responseType: 'blob' as 'json' });
  }
  getFilefromChat(contractId: any): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST_GET_FILE_CHAT + contractId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceRequest>(url, { headers, responseType: 'blob' as 'json' });
  }
  getPreviousContracts(contractId: any): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.SERVICE_REQUEST_GET_PREVIOUS_CONTRACTS + contractId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceRequest>(url, { headers });
  }
  createServiceInquiry(ServiceInquiryCreate: ServiceInquiryCreate): Observable<ServiceInquiryObject> { 

    const formData = new FormData(); 
 
    Object.entries(ServiceInquiryCreate).forEach(([key, value]) => {  
      if(key=="preselectedComponents"){
        let valueToJSON = JSON.stringify(value);
        let array=new Blob([valueToJSON], { type: 'application/json' });
        formData.append(key,array);

      } 
       else if ( key=="additionalFiles") {
        value.forEach((arrayValue) => {
          formData.append(key, arrayValue);
        })
        
      }
      else if ( key=="status"||key=="acceptOfferStatus"||key=="companyDto"||key=="formDto"||key=="requestingType") {
       let  valueToJSON = JSON.stringify(value);
       
        let array=new Blob([valueToJSON], { type: 'application/json' });
        formData.append(key,array);
        
        
      }
      else {    
    
          let  valueToJSON = value;
       
        let array=new Blob([valueToJSON], { type: 'application/json' });
        formData.append(key,array);
      }
     
    });
    const url = environment.apiRootUrl + mainApiRoutes.INQUIRY_SERVICE_REQUEST_CREATE;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.post<ServiceInquiryObject>(url, formData, { headers });
  }


  getServiceInquiry(serviceInquiryId: string): Observable<ServiceInquiryObject> {
    const url = environment.apiRootUrl + mainApiRoutes.INQUIRY_SERVICE_REQUEST_GET + serviceInquiryId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<ServiceInquiryObject>(url, { headers });
  }

  updateServiceInquiry(serviceInquiryId: string, serviceInquiryObject: ServiceInquiryObject): Observable<ServiceInquiryObject> {
    const url = environment.apiRootUrl + mainApiRoutes.INQUIRY_SERVICE_REQUEST_UPDATE + serviceInquiryId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.put<ServiceInquiryObject>(url, serviceInquiryObject, { headers });

  }
  updateServiceInquiryPublic(serviceInquiryId: string, serviceInquiryObject: ServiceInquiryObject): Observable<ServiceInquiryObject> {
    const url = environment.apiRootUrl + mainApiRoutes.INQUIRY_SERVICE_REQUEST_UPDATE + serviceInquiryId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.put<ServiceInquiryObject>(url, serviceInquiryObject, { headers });

  }
  createOffer(serviceInquiryId: string, data:OfferCreateModel): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.INQUIRY_SERVICE_REQUEST_CREATE_OFFER + serviceInquiryId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.post<any>(url, data, { headers });
  }



  
 
   
  
  getOffers(serviceInquiryId: string): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.INQUIRY_SERVICE_REQUEST_GET_OFFERS + serviceInquiryId;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.get<any>(url, { headers });
  }
  updateOffers(data: OfferUpdateModel): Observable<any> {
    const url = environment.apiRootUrl + mainApiRoutes.INQUIRY_SERVICE_REQUEST_UPDATE_OFFERS;
    const headers = this.mainHeadersService.HTTP_CLIENT_HEADERS();
    return this.http.put<any>(url, data, { headers });
  }
  
  

}



